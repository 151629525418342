* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* link tag */
a {
  color: black;
  text-decoration: none;
}

/* input tag and fields */
input {
  width: 100%;
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
  color: black;
}
input:focus,
input:active,
input:focus-visible {
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
}

textarea {
  width: 100%;
  outline: none;
  outline-width: 0px;
  border: 0px solid;
  color: black;
  padding: 8px;
}
textarea:focus,
textarea:active,
textarea:focus-visible {
  outline: none;
  outline-width: 0px;
  border: 0px solid;
}

/* scrollbar */
.woScroll {
  overflow: overlay;
}
.woScroll::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}
.woScroll::-webkit-scrollbar-button,
.woScroll::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}
.woScroll::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}
.woScroll::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}
.woScroll::-webkit-scrollbar-track {
  background: transparent;
}

/* active tab */
.activeCategoryLink {
  font-weight: 600;
  border-bottom: 2px solid #0054a7;
}

.MuiPopover-paper{
  width:300px !important;
}



.iagScroll {
  overflow: auto;
}

.iagScroll::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.iagScroll::-webkit-scrollbar-button,
.iagScroll::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}

.iagScroll::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.iagScroll::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}

.iagScroll::-webkit-scrollbar-track {
  background: transparent;
}